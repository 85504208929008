import {createGlobalStyle, withTheme} from "styled-components";
import fontFiles from "./Fonts";
import {media} from "utils/Media"

const GlobalStyles = createGlobalStyle`
/* 
  @font-face {
    font-family: "Proxima Nova W05 Light";
    src: url(${fontFiles.ProximaNovaW05LightEOT});
    src: url(${fontFiles.ProximaNovaW05LightEOT}) format("eot"),
    url(${fontFiles.ProximaNovaW05LightWOFF2}) format("woff2"),
    url(${fontFiles.ProximaNovaW05LightWOFF}) format("woff"),
    url(${fontFiles.ProximaNovaW05LightTTF}) format("truetype");
    font-display: swap;
  }

  @font-face {
    font-family: "Proxima Nova W05 Regular";
    src: url(${fontFiles.ProximaNovaW05RegularEOT});
    src: url(${fontFiles.ProximaNovaW05RegularEOT}) format("eot"),
    url(${fontFiles.ProximaNovaW0RegularWOFF2}) format("woff2"),
    url(${fontFiles.ProximaNovaW05RegularWOFF}) format("woff"),
    url(${fontFiles.ProximaNovaW05RegularTTF}) format("truetype");
    font-display: swap;
  }

  @font-face {
    font-family: "Proxima Nova W05 Medium";
    src: url(${fontFiles.ProximaNovaW05MediumEOT});
    src: url(${fontFiles.ProximaNovaW05MediumEOT}) format("eot"),
    url(${fontFiles.ProximaNovaW05MediumWOFF2}) format("woff2"),
    url(${fontFiles.ProximaNovaW05MediumWOFF}) format("woff"),
    url(${fontFiles.ProximaNovaW05MediumTTF}) format("truetype");
    font-display: swap;
  }

  @font-face {
    font-family: "Proxima Nova W05 Bold";
    src: url(${fontFiles.ProximaNovaW05BoldEOT});
    src: url(${fontFiles.ProximaNovaW05BoldEOT}) format("eot"),
    url(${fontFiles.ProximaNovaW05BoldWOFF2}) format("woff2"),
    url(${fontFiles.ProximaNovaW05BoldWOFF}) format("woff"),
    url(${fontFiles.ProximaNovaW05BoldTTF}) format("truetype");
    font-display: swap;
  }

  @font-face {
    font-family: "noto_sansbold";
    src: url(${fontFiles.noto_sansboldWOFF2}) format("woff2"),
    url(${fontFiles.noto_sansboldWOFF}) format("woff"),
    url(${fontFiles.noto_sansboldTTF}) format("truetype");
    font-display: swap;
  } */

  @font-face {
    font-family: "noto_sansregular";
    src: url(${fontFiles.noto_sansregularWOFF2}) format("woff2"),
    url(${fontFiles.noto_sansregularWOFF}) format("woff"),
    url(${fontFiles.noto_sansregularTTF}) format("truetype");
    font-display: swap;
  }

  @font-face {
    font-family: "Turquoise W05 Inline";
    src: url(${fontFiles.TurquoiseW05InlineEOT});
    src: url(${fontFiles.TurquoiseW05InlineEOT}) format("eot"),
    url(${fontFiles.TurquoiseW05InlineWOFF2}) format("woff2"),
    url(${fontFiles.TurquoiseW05InlineWOFF}) format("woff"),
    url(${fontFiles.TurquoiseW05InlineTTF}) format("truetype");
    font-display: swap;
  }

  .nav-active {
    overflow: hidden;
    height: 100%;
    position: relative;
}
html {
  scroll-behavior: smooth;

}
  body {
    font-family: ${props => props.theme.font.family.body};
    font-size: ${props => props.theme.font.size.base};
    font-weight: normal;
    line-height: ${props => props.theme.font.lineHeight.base};
    color: ${props => props.theme.colors.black};
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: ${props => props.theme.font.family.bold};
    font-weight: bold;
    font-weight: normal;
  }

  h1, h2, .h1, .h2 {
    font-size: ${props => props.theme.font.h2.xs.size};
    line-height: ${props => props.theme.font.h2.xs.lineHeight};

    @media ${media.sm} {
      font-size: ${props => props.theme.font.h2.sm.size};
      line-height: ${props => props.theme.font.h2.sm.lineHeight};
    }

    @media ${media.lg} {
      font-size: ${props => props.theme.font.h2.size};
      line-height: ${props => props.theme.font.h2.lineHeight};
    }

    &.font--bigger {
      font-size: ${props => props.theme.font.h2.bigger.xs.size};
      line-height: ${props => props.theme.font.h2.bigger.xs.lineHeight};

      @media ${media.sm} {
        font-size: ${props => props.theme.font.h2.bigger.sm.size};
        line-height: ${props => props.theme.font.h2.bigger.sm.lineHeight};
      }

      @media ${media.lg} {
        font-size: ${props => props.theme.font.h2.bigger.size};
        line-height: ${props => props.theme.font.h2.bigger.lineHeight};
      }
    }

    &.font--smaller {
      font-size: ${props => props.theme.font.h2.smaller.xs.size};
      line-height: ${props => props.theme.font.h2.smaller.xs.lineHeight};

      @media ${media.sm} {
        font-size: ${props => props.theme.font.h2.smaller.sm.size};
        line-height: ${props => props.theme.font.h2.smaller.sm.lineHeight};
      }

      @media ${media.lg} {
        font-size: ${props => props.theme.font.h2.smaller.size};
        line-height: ${props => props.theme.font.h2.smaller.lineHeight};
      }
    }
  }

  h3 {
    font-size: ${props => props.theme.font.h3.sm.size};
    line-height: ${props => props.theme.font.h3.sm.lineHeight};

    @media ${media.lg} {
      font-size: ${props => props.theme.font.h3.size};
      line-height: ${props => props.theme.font.h3.lineHeight};
    }
  }

  h4 {
    font-size: ${props => props.theme.font.h4.sm.size};
    line-height: ${props => props.theme.font.h4.sm.lineHeight};

    @media ${media.md} {
      font-size: ${props => props.theme.font.h4.md.size};
      line-height: ${props => props.theme.font.h4.md.lineHeight};
    }

    @media ${media.xl} {
      font-size: ${props => props.theme.font.h4.size};
      line-height: ${props => props.theme.font.h4.lineHeight};
    }
  }

  h5 {
    font-size: ${props => props.theme.font.h5.sm.size};
    line-height: ${props => props.theme.font.h5.sm.lineHeight};

    @media ${media.lg} {
      font-size: ${props => props.theme.font.h5.size};
      line-height: ${props => props.theme.font.h5.lineHeight};
    }

    &.font--bigger {
      font-size: ${props => props.theme.font.h5.bigger.sm.size};
      line-height: ${props => props.theme.font.h5.bigger.sm.lineHeight};

      @media ${media.lg} {
        font-size: ${props => props.theme.font.h5.bigger.size};
        line-height: ${props => props.theme.font.h5.bigger.lineHeight};
      }
    }
  }

  h6 {
    font-size: ${props => props.theme.font.h6.sm.size};
    line-height: ${props => props.theme.font.h6.sm.lineHeight};

    @media ${media.lg} {
      font-size: ${props => props.theme.font.h6.size};
      line-height: ${props => props.theme.font.h6.lineHeight};
    }
  }

  p, ol, ul {
    font-size: ${props => props.theme.font.p.sm.size};
    line-height: ${props => props.theme.font.p.sm.lineHeight};

    @media ${media.lg} {
      font-size: ${props => props.theme.font.p.size};
      line-height: ${props => props.theme.font.p.lineHeight};
    }

    &.smaller {
      font-size: ${props => props.theme.font.p.smaller.sm.size};
      line-height: ${props => props.theme.font.p.smaller.sm.lineHeight};

      @media ${media.lg} {
        font-size: ${props => props.theme.font.p.smaller.size};
        line-height: ${props => props.theme.font.p.smaller.lineHeight};
      }
    }

    &.bigger {
      font-size: ${props => props.theme.font.p.bigger.sm.size};
      line-height: ${props => props.theme.font.p.bigger.sm.lineHeight};

      @media ${media.lg} {
        font-size: ${props => props.theme.font.p.bigger.size};
        line-height: ${props => props.theme.font.p.bigger.lineHeight};
      }
    }
  }
  .navigation-link {
    color: #000;
    cursor: pointer;
    &:hover, &:active {
      color: #003199;
    }
  }
  a {
    color: ${props => props.theme.colors.navy};
    font-weight: ${props => props.theme.font.family.bold};

    &:hover {
      color: ${props => props.theme.colors.navy};
    }
  }

  ul {
    padding-left: 20px;
  }

  img {
    max-width: 100%;
  }

  .video-wrapper {
    position: relative;
    //padding-bottom: 75%; /* 4:3 aspect ratio */
    padding-bottom: 56.25%; /* 16:9 aspect ratio */
    padding-top: 0; // height of controls
    height: 0;

    iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }

    .posterImage {
      position: absolute;
      display: block;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 2;
      cursor: pointer;

      &.playing {
        display: none;
      }
    }
  }

  .play-icon {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 70px;

    @media ${media.lg} {
      width: 120px;
    }
  }

  .modal-backdrop.show {
    background: #003199;
    opacity: 0.8;
  }

  //.modal-dialog {
  //    max-width: calc(100% - 30px);
  //    margin: 1.75rem auto;
  //}

  /* #main {
    margin-top: 60px;
    @media ${media.sm} {
      margin-top: 55px;
    }
    @media ${media.lg} {
      margin-top: 74px;
    }

    &.enrichmentHeader {
      margin-top: 100px;
      @media (min-width: 992px) {
        margin-top: 140px;
      }
    }

    &.subMenuHidden {
      margin-top: 70px !important;
    }

    &.interstitial {
      margin-top: 0;
    }
  } */

  .container {
    margin-left: auto;
    margin-right: auto;
  }

  .slider-arrow {
    position: absolute;
    z-index: 10;
    top: calc(0% + 45%);
    border: none;
    background: ${props => props.theme.colors.darkBlue};
    color: #fff;
    width: 45px;
    height: 45px;
    font-size: 20px;
    cursor: pointer;

  }

  .next {

    right: 0;
  }

  .prev {

    left: 0;
  }

  .hygge {
    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    min-height: 15px !important;
  }

  .lineHeightSmaller {
    line-height: 14px;
    margin-top: .5rem;
    min-height: 15px !important;
  }

  /* .c-collapse__item {
    display: flex;
    align-items: center;
    justify-content: center;
  } */
  #site-wrap {
    position: relative
  }

  .pride-grad {
    background: linear-gradient(to right, rgb(237, 34, 36), rgb(243, 91, 34), rgb(249, 150, 33), rgb(245, 193, 30), rgb(241, 235, 27) 27%, rgb(241, 235, 27), rgb(241, 235, 27) 33%, rgb(99, 199, 32), rgb(12, 155, 73), rgb(33, 135, 141), rgb(57, 84, 165), rgb(97, 55, 155), rgb(147, 40, 142));
    span {
      /* color: black; */
    }
  }


`;

export default withTheme(GlobalStyles);
