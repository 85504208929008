import ProximaNovaW05LightEOT from "./fonts/c48b9e64-9616-4651-8136-26dcc90b518f.eot?#iefix";
import ProximaNovaW05LightWOFF2 from "./fonts/27f5855e-1f73-42cc-a083-cb0f319ea42a.woff2";
import ProximaNovaW05LightWOFF from "./fonts/f44944f0-b92e-4152-9ea1-eb41de241416.woff";
import ProximaNovaW05LightTTF from "./fonts/b3ca6462-e5b3-4cfd-9c86-69bd1444273f.ttf";

import ProximaNovaW05RegularEOT from "./fonts/3bc45708-b148-4161-9d38-fa10e8cd8ed7.eot?#iefix";
import ProximaNovaW05RegularWOFF2 from "./fonts/33ca2000-af7b-4444-97cd-6392ab2e24b2.woff2";
import ProximaNovaW05RegularWOFF from "./fonts/1c003cc6-0f8f-4c82-adde-a5a026b56b50.woff";
import ProximaNovaW05RegularTTF from "./fonts/96dc6d98-013d-4a94-9c09-8476abc0abc6.ttf";

import ProximaNovaW05MediumEOT from "./fonts/e49a997f-7512-4cb2-b647-589aaacffab5.eot?#iefix";
import ProximaNovaW05MediumWOFF2 from "./fonts/fefa128d-8eb9-48e9-adf7-568b92a2e991.woff2";
import ProximaNovaW05MediumWOFF from "./fonts/8c025621-11f1-4af4-8dfa-69eb993b51b9.woff";
import ProximaNovaW05MediumTTF from "./fonts/7b58123f-5968-4e40-a0e0-6568b05ed816.ttf";

import ProximaNovaW05BoldEOT from "./fonts/9a48aea6-1175-475c-9ca0-e88402e8c3e3.eot?#iefix";
import ProximaNovaW05BoldWOFF2 from "./fonts/3c35dc60-9fe3-480f-90ac-e453db0271e2.woff2";
import ProximaNovaW05BoldWOFF from "./fonts/2b2b7939-693b-44cd-873a-1aa065a906b8.woff";
import ProximaNovaW05BoldTTF from "./fonts/b3268637-7b92-4f81-9d7f-1ee8e8a5d517.ttf";

import noto_sansboldWOFF2 from "./fonts/notosans-bold-webfont.woff2";
import noto_sansboldWOFF from "./fonts/notosans-bold-webfont.woff";
import noto_sansboldTTF from "./fonts/notosans-bold-webfont.ttf";

import noto_sansregularWOFF2 from "./fonts/notosans-regular-webfont.woff2";
import noto_sansregularWOFF from "./fonts/notosans-regular-webfont.woff";
import noto_sansregularTTF from "./fonts/notosans-regular-webfont.ttf";

import TurquoiseW05InlineEOT from "./fonts/dd805563-c51d-4e72-9f13-7bcffb8c5c28.eot?#iefix";
import TurquoiseW05InlineWOFF2 from "./fonts/fde16880-e6d7-449c-aef2-7aa6dd977118.woff2";
import TurquoiseW05InlineWOFF from "./fonts/99b977a1-b764-4eb9-ae07-76a78672eb9e.woff";
import TurquoiseW05InlineTTF from "./fonts/296b6fd4-209f-45d6-b8c3-d2cebb7d987c.ttf";

export default {
    ProximaNovaW05LightEOT,
    ProximaNovaW05LightWOFF2,
    ProximaNovaW05LightWOFF,
    ProximaNovaW05LightTTF,

    ProximaNovaW05RegularEOT,
    ProximaNovaW05RegularWOFF2,
    ProximaNovaW05RegularWOFF,
    ProximaNovaW05RegularTTF,

    ProximaNovaW05MediumEOT,
    ProximaNovaW05MediumWOFF2,
    ProximaNovaW05MediumWOFF,
    ProximaNovaW05MediumTTF,

    ProximaNovaW05BoldEOT,
    ProximaNovaW05BoldWOFF2,
    ProximaNovaW05BoldWOFF,
    ProximaNovaW05BoldTTF,

    noto_sansboldWOFF2,
    noto_sansboldWOFF,
    noto_sansboldTTF,

    noto_sansregularWOFF2,
    noto_sansregularWOFF,
    noto_sansregularTTF,

    TurquoiseW05InlineEOT,
    TurquoiseW05InlineWOFF2,
    TurquoiseW05InlineWOFF,
    TurquoiseW05InlineTTF
};